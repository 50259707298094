import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { showSuccess, error } from "../../MessageHelper";
import { handleRequest } from "../../ApiConnector";
import { Form, Checkbox, Col, Row, Badge, Spin } from "antd";

export class ExpenseLogPaymentTermsBaseOn extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "ExpenseLogPaymentTermsBaseOn",
      controllerName: "expenseLogPaymentTermsBaseOn",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],

      searchCriteriaModels: [],
      saveLoading: false
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random) this.initialize();
  };

  initialize = async () => {
    this.restartTable();
    this.fillComboboxes();
  };

  fillComboboxes = async () => {};

  restartTable = async () => {
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/getBaseOn");
    if (response.type === "ERROR") {
      error(response);
    }
    var row = Boolean(response.data) ? response.data : null;
    this.formRef.current.setFieldsValue({
      ...row
    });
  };

  save = async values => {
    this.setState({ saveLoading: true });
    const newItem = {
      ...values
    };
    var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/save", newItem);
    if (response.type === "ERROR") {
      error(response);
      this.setState({ saveLoading: false });
    } else {
      showSuccess();
      this.props.closeExpenseLogPaymentTermsBaseOn();
      this.setState({ saveLoading: false });
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };
    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
          {
            <Form.Item
              {...layout}
              label={<FormattedMessage id="PageFormInput.Payment" defaultMessage="Payment" />}
              name="payment"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              valuePropName="checked"
            >
              <Checkbox></Checkbox>
            </Form.Item>
          }
          {
            <Form.Item
              {...layout}
              label={<FormattedMessage id="PageFormInput.Documentation" defaultMessage="Documentation" />}
              name="documentation"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              valuePropName="checked"
            >
              <Checkbox></Checkbox>
            </Form.Item>
          }
          {
            <Form.Item
              {...layout}
              label={<FormattedMessage id="PageFormInput.Manufacturing" defaultMessage="Manufacturing" />}
              name="manufacturing"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              valuePropName="checked"
            >
              <Checkbox></Checkbox>
            </Form.Item>
          }
          {
            <Form.Item
              {...layout}
              label={<FormattedMessage id="PageFormInput.ReceivingQuantity" defaultMessage="Receiving Quantity" />}
              name="receivingQuantity"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              valuePropName="checked"
            >
              <Checkbox></Checkbox>
            </Form.Item>
          }
          {
            <Row gutter={[16, 16]}>
              <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <Spin spinning={this.state.saveLoading}>
                  <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                    <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                  </Button>
                </Spin>
              </Col>
              <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
            </Row>
          }
        </Form>
        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          {" "}
          <Modal.Header closeButton>
            {" "}
            <Modal.Title id="contained-modal-title-vcenter">
              {" "}
              <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />{" "}
            </Modal.Title>{" "}
          </Modal.Header>{" "}
          <Modal.Body>
            {" "}
            <p>
              {" "}
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />{" "}
            </p>{" "}
          </Modal.Body>{" "}
          <Modal.Footer>
            {" "}
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              {" "}
              <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />{" "}
            </Button>{" "}
            <Button variant="danger" onClick={this.delete}>
              {" "}
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />{" "}
            </Button>{" "}
          </Modal.Footer>{" "}
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(ExpenseLogPaymentTermsBaseOn);
