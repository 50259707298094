import React, { useState, useEffect } from "react";
import { handleRequest } from "../../ApiConnector";
import { error } from "../../MessageHelper";
import { Table, Col, Select, Row, DatePicker, Progress, Tooltip } from "antd";
import { FormattedMessage } from "react-intl";
import { Pie } from "@ant-design/plots";
import { Line } from "@ant-design/plots";
import { Column, Radar } from "@ant-design/charts";
import moment from "moment";

const { Option } = Select;

const ProgressBar = () => {
  const [tableList, setTableList] = useState([]);
  const [startDate, setStartDate] = useState(moment().subtract(6, "M"));
  const [pieChartList, setPieChartList] = useState([]);
  const [endDate, setEndDate] = useState(moment());
  const [progressType, setProgressType] = useState("table");
  const [radarVisible, setRadarVisible] = useState(false);
  const [tableVisible, setTableVisible] = useState(true);
  const [pieVisible, setPieVisible] = useState(false);
  const [lineVisible, setLineVisible] = useState(false);
  const [columnVisible, setColumnVisible] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    showSizeChanger: true,
    pageSizeOptions: ["2", "5", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
    total: 0
  });

  useEffect(() => {
    asyncFetch();
  }, [startDate, endDate]);

  const asyncFetch = async () => {
    restartTable({ pagination }, progressType);
  };
  const handleTableChange = (pagination, filters, sorter) => {
    restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  const restartTable = async (params = {}, progressType) => {
    const search = {
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize
    };
    console.log(search);
    var response = await handleRequest("POST", "/api/dashboard/customerCreditStatus/" + progressType, search);
    if (response.type === "ERROR") {
      error(response);
    } else {
      setTableList(Boolean(response.data.data) ? response.data.data : null);
      setPagination({
        ...params.pagination,
        total: response.data.totalRecords
      });
    }
    var responsePieChart = await handleRequest("POST", "/api/dashboard/customerCreditStatusPieChart", search);
    if (responsePieChart.type === "ERROR") {
      error(responsePieChart);
    } else {
      setPieChartList(Boolean(responsePieChart.data) ? responsePieChart.data : null);
    }
  };
  var pieConfig = {
    appendPadding: 10,
    data: pieChartList,
    angleField: "key",
    colorField: "value",
    radius: 1,
    innerRadius: 0.6,
    label: {
      type: "inner",
      offset: "-50%",
      content: "{value}",
      style: {
        textAlign: "center",
        fontSize: 14
      }
    },
    interactions: [
      {
        type: "element-selected"
      },
      {
        type: "element-active"
      },
      {
        type: "pie-statistic-active"
      }
    ]
    // statistic: {
    //   title: false,
    //   content: {
    //     offsetY: 4,
    //     style: {
    //       fontSize: '32px',
    //     },
    //     customHtml: (container, view, datum, data) => {

    //       const { width } = container.getBoundingClientRect();
    //       const text = datum ? `${datum.key}` : `${data.reduce((r, d) => r + d.key, 0)}`;
    //       return this.renderStatistic(width, text, {
    //         fontSize: 32,
    //       });
    //     },
    //   }
    // }
  };
  const barConfig = {
    data: pieChartList,
    isGroup: true,
    xField: "value",
    yField: "key",
    seriesField: "value",
    yAxis: {
      label: {
        formatter: v => `${v}`,
        layout: [{ type: "interval-adjust-position" }, { type: "interval-hide-overlap" }, { type: "adjust-color" }]
      }
    },
    color: ["#1979C9", "#D62A0D", "#FAA219", "#FF99C3"]
  };
  const lineConfig = {
    data: pieChartList,
    xField: "value",
    yField: "key",
    seriesField: "value",
    yAxis: {
      label: {
        formatter: v => `${v}`
      }
    },
    color: ["#1979C9", "#D62A0D", "#FAA219", "#FF99C3"]
  };

  const handleSelectChange = value => {
    switch (value) {
      case "table":
        setTableVisible(true);
        setRadarVisible(false);
        setPieVisible(false);
        setColumnVisible(false);
        setLineVisible(false);
        break;
      case "line":
        setLineVisible(true);
        setRadarVisible(false);
        setPieVisible(false);
        setColumnVisible(false);
        setTableVisible(false);
        break;

      case "progress":
        setTableVisible(true);
        setRadarVisible(false);
        setPieVisible(false);
        setColumnVisible(false);
        setLineVisible(false);
        break;

      case "circle":
        setTableVisible(true);
        setRadarVisible(false);
        setPieVisible(false);
        setColumnVisible(false);
        setLineVisible(false);
        break;
      case "radar":
        setRadarVisible(true);
        setPieVisible(false);
        setColumnVisible(false);
        setLineVisible(false);
        setTableVisible(false);
        break;
      case "pie":
        setPieVisible(true);
        setLineVisible(false);
        setColumnVisible(false);
        setTableVisible(false);
        setRadarVisible(false);
        break;
      case "column":
        setColumnVisible(true);
        setLineVisible(false);
        setTableVisible(false);
        setRadarVisible(false);
        setPieVisible(false);
        break;
    }
  };

  const columns = [
    {
      title: "",
      key: "type",
      render: record => {
        return record.type;
      }
    },

    {
      title: <FormattedMessage id="ProgressBar.Number" defaultMessage="Number" />,
      key: "number",
      render: (value, row, index) => {
        if (progressType === "table") {
          return row.number;
        } else {
          return <Progress type={progressType} percent={row.number} status="active" strokeColor={{ from: "#108ee9", to: "#87d068" }} />;
        }
      },
      align: "center"
    },
    {
      title: <FormattedMessage id="ProgressBar.TotalAmount" defaultMessage="Total Amount" />,
      key: "totalAmount",
      render: (value, row, index) => {
        if (progressType === "table") {
          return row.totalAmount;
        } else {
          return <Progress type={progressType} percent={row.totalAmount} status="active" strokeColor={{ from: "#108ee9", to: "#87d068" }} />;
        }
      },
      align: "center"
    },
    {
      title: <FormattedMessage id="ProgressBar.NumberNotPaid" defaultMessage="Number Not Paid" />,
      key: "numberNotPaid",
      render: (value, row, index) => {
        if (progressType === "table") {
          return row.numberNotPaid;
        } else {
          return <Progress type={progressType} percent={row.numberNotPaid} status="active" strokeColor={{ from: "#108ee9", to: "#87d068" }} />;
        }
      },
      align: "center"
    },
    {
      title: <FormattedMessage id="ProgressBar.TotalAmountNotPaid" defaultMessage="Total Amount Not Paid" />,
      key: "totalAmountNotPaid",
      render: (value, row, index) => {
        if (progressType === "table") {
          return row.totalAmountNotPaid;
        } else {
          return <Progress type={progressType} percent={row.totalAmountNotPaid} status="active" strokeColor={{ from: "#108ee9", to: "#87d068" }} />;
        }
      },
      align: "center"
    }
  ];

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
        <Tooltip title="3 done / 3 in progress / 4 to do">
          <Progress
            percent={60}
            success={{
              percent: 45
            }}
            type="dashboard"
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default ProgressBar;
