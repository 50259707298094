import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal, Table as TableBoot } from "react-bootstrap";
import { showError, showSuccess, error } from "../../MessageHelper";
import { handleRequest } from "../../ApiConnector";
import { Form, Col, Row, Button as AntButton, Badge, Tooltip, Drawer, Select, Space } from "antd";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { EditOutlined, FilterList, ListAlt } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import store from "../../../redux/store";
import { PageSettings } from "../administrator/pageSettings/PageSettings";
import { Input } from "antd";
import { DownOutlined, MinusCircleOutlined, PlusOutlined, UpOutlined } from "@ant-design/icons";
import { MethodOfStatementStepsDrawer } from "./MethodOfStatementStepsDrawer";

const { Option } = Select;

export class MethodOfStatementSteps extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "MethodOfStatementSteps",
      controllerName: "methodOfStatementSteps",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      user: store.getState().auth.user,
      tableList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      }
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    //this.fillComboboxes();
  };

  fillComboboxes = async () => {
    var responseActivityId = await handleRequest("GET", "/api/" + this.state.controllerName + "/activity");
    if (responseActivityId.type === "ERROR") {
      error(responseActivityId);
    } else {
      this.setState({
        activityIdSelectItems: Boolean(responseActivityId.data) ? responseActivityId.data : []
      });
    }

    var responsePerformanceCriteriaId = await handleRequest("GET", "/api/" + this.state.controllerName + "/performanceCriteria");
    if (responsePerformanceCriteriaId.type === "ERROR") {
      error(responsePerformanceCriteriaId);
    } else {
      this.setState({
        performanceCriteriaIdSelectItems: Boolean(responsePerformanceCriteriaId.data) ? responsePerformanceCriteriaId.data : []
      });
    }

    var responseItemId = await handleRequest("GET", "/api/" + this.state.controllerName + "/item");
    if (responseItemId.type === "ERROR") {
      error(responseItemId);
    } else {
      this.setState({
        itemIdSelectItems: Boolean(responseItemId.data) ? responseItemId.data : []
      });
    }

    var responseEquipmentId = await handleRequest("GET", "/api/" + this.state.controllerName + "/equipment");
    if (responseEquipmentId.type === "ERROR") {
      error(responseEquipmentId);
    } else {
      this.setState({
        equipmentIdSelectItems: Boolean(responseEquipmentId.data) ? responseEquipmentId.data : []
      });
    }

    var responseToolId = await handleRequest("GET", "/api/" + this.state.controllerName + "/tool");
    if (responseToolId.type === "ERROR") {
      error(responseToolId);
    } else {
      this.setState({
        toolIdSelectItems: Boolean(responseToolId.data) ? responseToolId.data : []
      });
    }

    var responseManpowerId = await handleRequest("GET", "/api/" + this.state.controllerName + "/manpower");
    if (responseManpowerId.type === "ERROR") {
      error(responseManpowerId);
    } else {
      this.setState({
        manpowerIdSelectItems: Boolean(responseManpowerId.data) ? responseManpowerId.data : []
      });
    }

    var responseDepartmentId = await handleRequest("GET", "/api/" + this.state.controllerName + "/department");
    if (responseDepartmentId.type === "ERROR") {
      error(responseDepartmentId);
    } else {
      this.setState({
        departmentIdSelectItems: Boolean(responseDepartmentId.data) ? responseDepartmentId.data : []
      });
    }

    var responseDocumentId = await handleRequest("GET", "/api/" + this.state.controllerName + "/document");
    if (responseDocumentId.type === "ERROR") {
      error(responseDocumentId);
    } else {
      this.setState({
        documentIdSelectItems: Boolean(responseDocumentId.data) ? responseDocumentId.data : []
      });
    }

    var responseFigureId = await handleRequest("GET", "/api/" + this.state.controllerName + "/figure");
    if (responseFigureId.type === "ERROR") {
      error(responseFigureId);
    } else {
      this.setState({
        figureIdSelectItems: Boolean(responseFigureId.data) ? responseFigureId.data : []
      });
    }

    var responseVideoId = await handleRequest("GET", "/api/" + this.state.controllerName + "/video");
    if (responseVideoId.type === "ERROR") {
      error(responseVideoId);
    } else {
      this.setState({
        videoIdSelectItems: Boolean(responseVideoId.data) ? responseVideoId.data : []
      });
    }
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };
  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    });
  };
  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };
  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };
  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id
    };

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };
  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };
  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };
  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };
    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
          <Row gutter={[16, 16]}>
            <Col md={20}></Col>
            <Col md={1}></Col>
            <Col md={1}></Col>
            <Col md={1}>
              <Tooltip title={<FormattedMessage id="MethodOfStatementSteps.Order" defaultMessage="Order" />}>
                <AntButton
                  style={{ border: "0px" }}
                  onClick={() => this.setState(trueFalseState => ({ order: !trueFalseState.order }))}
                  icon={this.state.order ? <UpOutlined /> : <DownOutlined />}
                />
              </Tooltip>
            </Col>
            <Col md={1}>
              <Tooltip title={<FormattedMessage id="MethodOfStatementSteps.Settings" defaultMessage="Settings" />}>
                <AntButton
                  style={{ border: "0px" }}
                  onClick={() => {
                    this.setState({ settingsShow: true });
                  }}
                  icon={<FilterList />}
                />
              </Tooltip>
            </Col>
            {/* <Col md={1}>
                {Boolean(this.state.user) && this.state.user.role === "ADMIN" && (
                  <Tooltip title={<FormattedMessage id="MethodOfStatementSteps.Settings" defaultMessage="Settings" />}>
                    <AntButton
                      style={{ border: "0px" }}
                      onClick={() => {
                        this.setState({ drawerSettingsShow: true });
                      }}
                      icon={<Settings color="error" fontSize="large" />}
                    ></AntButton>
                  </Tooltip>
                )}
              </Col> */}
          </Row>

          <Form.List name="methodOfStatementStepsList">
            {(fields, { add, remove }) => {
              const handleAddClick = () => {
                add();
              };

              return (
                <>
                  <TableBoot bordered size="sm">
                    <thead>
                      <tr>
                        <th colSpan="1" style={{ textAlign: "center" }}>
                          <FormattedMessage id="MethodOfStatementSteps.No" defaultMessage="No" />
                        </th>
                        <th colSpan="1" style={{ textAlign: "center" }}>
                          <FormattedMessage id="MethodOfStatementSteps.Description" defaultMessage="Description" />
                        </th>
                        <th colSpan="1" style={{ textAlign: "center" }}>
                          <FormattedMessage id="MethodOfStatementSteps.Activity" defaultMessage="Activity" />
                        </th>
                        <th colSpan="1" style={{ textAlign: "center" }}>
                          <FormattedMessage id="MethodOfStatementSteps.PerformanceCriteria" defaultMessage="Performance Criteria" />
                        </th>
                        <th colSpan="1" style={{ textAlign: "center", width: "150px" }}>
                          <FormattedMessage id="MethodOfStatementSteps.Item" defaultMessage="Item" />
                        </th>
                        <th colSpan="1" style={{ textAlign: "center", width: "150px" }}>
                          <FormattedMessage id="MethodOfStatementSteps.Equipment" defaultMessage="Equipment" />
                        </th>
                        <th colSpan="1" style={{ textAlign: "center", width: "150px" }}>
                          <FormattedMessage id="MethodOfStatementSteps.Tool" defaultMessage="Tool" />
                        </th>
                        <th colSpan="1" style={{ textAlign: "center", width: "150px" }}>
                          <FormattedMessage id="MethodOfStatementSteps.Manpower" defaultMessage="Manpower" />
                        </th>
                        <th colSpan="1" style={{ textAlign: "center" }}>
                          <FormattedMessage id="MethodOfStatementSteps.Department" defaultMessage="Department" />
                        </th>
                        <th colSpan="1" style={{ textAlign: "center" }}>
                          <FormattedMessage id="MethodOfStatementSteps.Document" defaultMessage="Document" />
                        </th>
                        <th colSpan="1" style={{ textAlign: "center" }}>
                          <FormattedMessage id="MethodOfStatementSteps.Figure" defaultMessage="Figure" />
                        </th>
                        <th colSpan="1" style={{ textAlign: "center" }}>
                          <FormattedMessage id="MethodOfStatementSteps.Video" defaultMessage="Video" />
                        </th>
                        <th colSpan="1" style={{ textAlign: "center" }}></th>
                      </tr>
                    </thead>
                    <tbody>
                      {fields.map((field, index) => {
                        return (
                          <tr key={field.key}>
                            <Form.Item hidden name={[field.name, "id"]}></Form.Item>
                            <td>{index + 1}</td>

                            <td>
                              <Form.Item style={{ width: "100%" }} name={[field.name, "description"]}>
                                <Input noStyle />
                              </Form.Item>
                            </td>

                            <td>
                              <Form.Item noStyle name={[field.name, "activityId"]}>
                                <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                                  {Boolean(this.state["activityIdSelectItems"]) &&
                                    this.state["activityIdSelectItems"].length > 0 &&
                                    this.state["activityIdSelectItems"].map(i => (
                                      <Option key={i.key} value={i.key}>
                                        {i.value}
                                      </Option>
                                    ))}
                                </Select>
                              </Form.Item>
                            </td>

                            <td>
                              <Form.Item noStyle name={[field.name, "performanceCriteriaId"]}>
                                <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                                  {Boolean(this.state["performanceCriteriaIdSelectItems"]) &&
                                    this.state["performanceCriteriaIdSelectItems"].length > 0 &&
                                    this.state["performanceCriteriaIdSelectItems"].map(i => (
                                      <Option key={i.key} value={i.key}>
                                        {i.value}
                                      </Option>
                                    ))}
                                </Select>
                              </Form.Item>
                            </td>

                            <td>
                              <Space.Compact style={{ width: "100%" }}>
                                <Form.Item style={{ width: "80%" }} name={[field.name, "itemId"]}>
                                  <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                                    {Boolean(this.state["itemIdSelectItems"]) &&
                                      this.state["itemIdSelectItems"].length > 0 &&
                                      this.state["itemIdSelectItems"].map(i => (
                                        <Option key={i.key} value={i.key}>
                                          {i.value}
                                        </Option>
                                      ))}
                                  </Select>
                                </Form.Item>

                                <AntButton
                                  type="default"
                                  style={{
                                    color: "black",
                                    background: "white",
                                    width: "20%"
                                  }}
                                  onClick={() => {
                                    this.setState({ showItemUnitModal: true });
                                  }}
                                  icon={<ListAlt />}
                                ></AntButton>
                              </Space.Compact>
                            </td>

                            <td>
                              <Space.Compact style={{ width: "100%" }}>
                                <Form.Item noStyle name={[field.name, "equipmentId"]}>
                                  <Select allowClear style={{ width: "80%" }} optionFilterProp="children">
                                    {Boolean(this.state["equipmentIdSelectItems"]) &&
                                      this.state["equipmentIdSelectItems"].length > 0 &&
                                      this.state["equipmentIdSelectItems"].map(i => (
                                        <Option key={i.key} value={i.key}>
                                          {i.value}
                                        </Option>
                                      ))}
                                  </Select>
                                </Form.Item>
                                <AntButton
                                  type="default"
                                  style={{
                                    color: "black",
                                    background: "white",
                                    width: "20%"
                                  }}
                                  onClick={() => {
                                    this.setState({ showEquipmentUnitModal: true });
                                  }}
                                  icon={<ListAlt />}
                                ></AntButton>
                              </Space.Compact>
                            </td>

                            <td>
                              <Space.Compact style={{ width: "100%" }}>
                                <Form.Item noStyle name={[field.name, "toolId"]}>
                                  <Select allowClear style={{ width: "90%" }} optionFilterProp="children">
                                    {Boolean(this.state["toolIdSelectItems"]) &&
                                      this.state["toolIdSelectItems"].length > 0 &&
                                      this.state["toolIdSelectItems"].map(i => (
                                        <Option key={i.key} value={i.key}>
                                          {i.value}
                                        </Option>
                                      ))}
                                  </Select>
                                </Form.Item>
                                <AntButton
                                  type="default"
                                  style={{
                                    color: "black",
                                    background: "white",
                                    width: "20%"
                                  }}
                                  onClick={() => {
                                    this.setState({ showToolUnitModal: true });
                                  }}
                                  icon={<ListAlt />}
                                ></AntButton>
                              </Space.Compact>
                            </td>

                            <td>
                              <Space.Compact style={{ width: "100%" }}>
                                <Form.Item noStyle name={[field.name, "manpowerId"]}>
                                  <Select allowClear style={{ width: "80%" }} optionFilterProp="children">
                                    {Boolean(this.state["manpowerIdSelectItems"]) &&
                                      this.state["manpowerIdSelectItems"].length > 0 &&
                                      this.state["manpowerIdSelectItems"].map(i => (
                                        <Option key={i.key} value={i.key}>
                                          {i.value}
                                        </Option>
                                      ))}
                                  </Select>
                                </Form.Item>
                                <AntButton
                                  type="default"
                                  style={{
                                    color: "black",
                                    background: "white",
                                    width: "20%"
                                  }}
                                  onClick={() => {
                                    this.setState({ showManpowerUnitModal: true });
                                  }}
                                  icon={<ListAlt />}
                                ></AntButton>
                              </Space.Compact>
                            </td>

                            <td>
                              <Form.Item noStyle name={[field.name, "departmentId"]}>
                                <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                                  {Boolean(this.state["departmentIdSelectItems"]) &&
                                    this.state["departmentIdSelectItems"].length > 0 &&
                                    this.state["departmentIdSelectItems"].map(i => (
                                      <Option key={i.key} value={i.key}>
                                        {i.value}
                                      </Option>
                                    ))}
                                </Select>
                              </Form.Item>
                            </td>

                            <td>
                              <Form.Item noStyle name={[field.name, "documentId"]}>
                                <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                                  {Boolean(this.state["documentIdSelectItems"]) &&
                                    this.state["documentIdSelectItems"].length > 0 &&
                                    this.state["documentIdSelectItems"].map(i => (
                                      <Option key={i.key} value={i.key}>
                                        {i.value}
                                      </Option>
                                    ))}
                                </Select>
                              </Form.Item>
                            </td>

                            <td>
                              <Form.Item noStyle name={[field.name, "figureId"]}>
                                <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                                  {Boolean(this.state["figureIdSelectItems"]) &&
                                    this.state["figureIdSelectItems"].length > 0 &&
                                    this.state["figureIdSelectItems"].map(i => (
                                      <Option key={i.key} value={i.key}>
                                        {i.value}
                                      </Option>
                                    ))}
                                </Select>
                              </Form.Item>
                            </td>

                            <td>
                              <Form.Item noStyle name={[field.name, "videoId"]}>
                                <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                                  {Boolean(this.state["videoIdSelectItems"]) &&
                                    this.state["videoIdSelectItems"].length > 0 &&
                                    this.state["videoIdSelectItems"].map(i => (
                                      <Option key={i.key} value={i.key}>
                                        {i.value}
                                      </Option>
                                    ))}
                                </Select>
                              </Form.Item>
                            </td>

                            <td>
                              <MinusCircleOutlined onClick={() => remove(field.name)} />
                            </td>
                          </tr>
                        );
                      })}
                      <tr>
                        <td colSpan={14}>
                          <AntButton type="dashed" onClick={handleAddClick} block icon={<PlusOutlined />}>
                            <FormattedMessage id={"HowToUse.AddNew"} defaultMessage={"Add New"} />
                          </AntButton>
                        </td>
                      </tr>
                    </tbody>
                  </TableBoot>
                </>
              );
            }}
          </Form.List>
          <div hidden={this.state.hideSave}>
            {
              <Row gutter={[16, 16]}>
                <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                  <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                    <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                  </Button>
                </Col>
                <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
              </Row>
            }
          </div>
          <div hidden={this.state.hideUpdate}>
            {
              <Row gutter={[16, 16]}>
                <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                  <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                    <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                  </Button>
                </Col>
                <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                  <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                    <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                  </Button>
                </Col>
                <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
              </Row>
            }
          </div>
        </Form>
        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
            </Button>
            <Button variant="danger" onClick={this.delete}>
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Button>
          </Modal.Footer>
        </Modal>
        <Drawer
          title={<FormattedMessage id="MethodOfStatementSteps.PageSettingsAuthorization" defaultMessage="Page Settings-Authorization" />}
          width={1200}
          closable={false}
          onClose={() => {
            this.setState({ drawerSettingsShow: false });
          }}
          open={this.state.drawerSettingsShow}
        >
          <PageSettings pageName={"MethodOfStatementSteps"} />
        </Drawer>

        <Drawer
          title={<FormattedMessage id="MethodOfStatementSteps.Settings" defaultMessage="Settings" />}
          width={1200}
          closable={false}
          onClose={() => {
            this.setState({ settingsShow: false });
          }}
          open={this.state.settingsShow}
        >
          <MethodOfStatementStepsDrawer />
        </Drawer>
      </div>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(MethodOfStatementSteps);
