import { EditOutlined } from "@material-ui/icons";
import CreateIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { Button as AntButton, Col, InputNumber, Form, Row, Select, Checkbox, Collapse } from "antd";
import "antd/dist/antd.css";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { handleRequest } from "../../ApiConnector";
import { error, showError, showSuccess } from "../../MessageHelper";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { Button, Modal, Col as ColBoot, Row as RowBoot, Form as FormBoot } from "react-bootstrap";
import { round } from "mathjs";
import { Badge } from "antd";

const { Option } = Select;
const { Panel } = Collapse;

export class ProcurementProgress extends Component {
  constructor() {
    super();
    this.state = {
      formName: "ProcurementProgress",
      controllerName: "procurementProgress",
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      itemClassIdSelectItems: [],
      id: null,
      tableList: [],
      excelData: [],
      createWhereParams: null,
      refreshForm: false,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });

    this.fillComboboxes();
  };

  componentDidUpdate = async nextProps => {};

  fillComboboxes = async () => {
    this.getItemClassess();
  };

  getItemClassess = async () => {
    const response = await handleRequest("GET", "/api/codes/type/StockCardItemClassId");
    if (Boolean(response.data)) {
      this.setState({
        itemClassIdSelectItems: response.data
      });
    }
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null
    });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();
    this.formRef.current.setFieldsValue({
      ...row
    });
    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.restartTable();
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    let response = await handleRequest("GET", "/api/" + this.state.controllerName + "/getAllByCompany");
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: []
      });
    } else {
      this.setState({
        loading: false,
        tableList: response.data
      });
    }
  };

  createWhere(params) {
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      searchCriteriaModels = [],
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder,
      searchCriteriaModels: searchCriteriaModels
    };
    return newObj;
  }

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    let newItem = {
      ...values,
      id: this.state.id
    };

    if (!Boolean(newItem.id)) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      this.restartTable();
      showSuccess();
    }
  };

  saveScoreAndWeight = async () => {
    var response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/saveScoreAndWeight", this.state.tableList);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      this.restartTable();
      showSuccess();
    }
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  action = record => {
    return (
      <React.Fragment>
        <DynamicActionMenu
          row={record}
          actionList={[
            {
              name: "Edit",
              icon: <EditOutlined fontSize="small" color="primary" />,
              actionClick: this.edit.bind(this)
            },
            {
              name: "Delete",
              icon: <DeleteIcon fontSize="small" color="error" />,
              actionClick: this.deleteModal.bind(this)
            }
          ]}
        />
      </React.Fragment>
    );
  };

  renderElement = tableList => {
    return (
      Boolean(tableList) &&
      tableList.length > 0 &&
      tableList.map((item, index) => (
        <Panel
          header={item.title}
          key={index}
          extra={
            <>
              <InputNumber
                min={0}
                value={item.score}
                placeholder="Score"
                onChange={value => {
                  const { tableList } = this.state;
                  let fChild = tableList[index];
                  fChild.score = value;
                  this.setState({ tableList }, this.calcWeight);
                }}
              ></InputNumber>

              <InputNumber disabled min={0} value={item.weight} placeholder="Weight"></InputNumber>
            </>
          }
        >
          {Boolean(item.children) && item.children.length > 0 && (
            <>
              <RowBoot style={{ marginTop: "2rem" }}>
                <ColBoot xs={1}>
                  <FormBoot.Label style={{ color: "black", marginTop: "0.5rem", fontWeight: "bold" }}>{"Select"}</FormBoot.Label>
                </ColBoot>
                <ColBoot xs={5}>
                  <FormBoot.Label style={{ color: "black", marginTop: "0.5rem", fontWeight: "bold" }}>{"Step"}</FormBoot.Label>
                </ColBoot>
                <ColBoot xs={2}>
                  <FormBoot.Label style={{ color: "black", marginTop: "0.5rem", fontWeight: "bold" }}>{"Score"}</FormBoot.Label>
                </ColBoot>
                <ColBoot xs={2}>
                  <FormBoot.Label style={{ color: "black", marginTop: "0.5rem", fontWeight: "bold" }}>{"Weight"}</FormBoot.Label>
                </ColBoot>
              </RowBoot>

              {item.children.map((child, childIndex) => (
                <RowBoot style={{ marginBottom: "1rem" }}>
                  <ColBoot xs={1}>
                    {" "}
                    <Checkbox
                      checked={child.checked}
                      onChange={e => {
                        const { tableList } = this.state;
                        let fChild = tableList[index].children[childIndex];
                        fChild.checked = e.target.checked;
                        if (!e.target.checked) {
                          fChild.score = 0;
                        }
                        this.setState({ tableList }, this.calcWeight);
                      }}
                    ></Checkbox>{" "}
                  </ColBoot>
                  <ColBoot xs={5}>
                    <FormBoot.Label style={{ color: "black", marginTop: "0.5rem" }}>{child.procurementProgressStepName}</FormBoot.Label>
                  </ColBoot>
                  <ColBoot xs={2}>
                    <InputNumber
                      min={0}
                      disabled={!child.checked}
                      value={child.score}
                      placeholder="Score"
                      onChange={value => {
                        const { tableList } = this.state;
                        let fChild = tableList[index].children[childIndex];
                        fChild.score = value;
                        this.setState({ tableList }, this.calcWeight);
                      }}
                    ></InputNumber>
                  </ColBoot>
                  <ColBoot xs={2}>
                    <InputNumber disabled min={0} value={child.weight} placeholder="Weight"></InputNumber>
                  </ColBoot>
                </RowBoot>
              ))}
            </>
          )}
        </Panel>
      ))
    );
  };

  calcWeight = () => {
    const { tableList, activePanelKey } = this.state;

    if (Boolean(activePanelKey)) {
      let tableItem = tableList[activePanelKey];
      let values = tableItem.children;
      if (Boolean(values) && values.length > 0) {
        let totalChildScore = 0;
        values.forEach((element, index) => {
          if (Boolean(element.score)) {
            totalChildScore += element.score;
          }
        });

        //tableItem.score = totalChildScore;

        values.forEach(child => {
          if (Boolean(child.score) && child.score > 0 && totalChildScore > 0) {
            child.weight = round((child.score / totalChildScore) * 100, 2);
          }
          if (!Boolean(child.score)) {
            child.weight = null;
          }
        });
      }
    }
    let totalTableScore = 0;
    tableList.forEach(element => {
      if (Boolean(element.score)) {
        totalTableScore += element.score;
      }
    });

    tableList.forEach(element => {
      if (Boolean(element.score) && element.score > 0 && totalTableScore > 0) {
        element.weight = round((element.score / totalTableScore) * 100, 2);
      }
      if (!Boolean(element.score)) {
        element.weight = null;
      }
    });

    this.setState({
      tableList: this.state.tableList
    });
  };

  setOpenPanels = key => {
    console.log(key);
    this.setState({
      activePanelKey: key
    });
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 },
      style: {
        marginBottom: 0
      }
    };

    return (
      <Badge.Ribbon text="Procurement Progress" placement="start" color="volcano" style={{ fontSize: 12 }}>
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Row gutter={[16, 16]}>
            <Col md={23}></Col>

            <Col md={1}>
              <AntButton
                style={{ border: "0px" }}
                hidden={!this.state.hideInputs}
                onClick={this.onClickCreateNew}
                icon={<CreateIcon color="secondary" fontSize="large" />}
              ></AntButton>
              <AntButton
                style={{ border: "0px" }}
                hidden={this.state.hideInputs}
                onClick={this.onClickCreateNew}
                icon={<RemoveCircle color="error" fontSize="large" />}
              ></AntButton>
            </Col>
          </Row>
          <div hidden={this.state.hideInputs}>
            <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
              <Form.Item
                {...layout}
                label={<FormattedMessage id="ProcurementProgress.itemClassId" defaultMessage="Item Class" />}
                name="itemClassId"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select allowClear showSearch style={{ width: "100%" }} placeholder="Item Class">
                  {this.state["itemClassIdSelectItems"].map(i => (
                    <Option key={i.id} value={i.id}>
                      {i.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <div>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                        <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
            </Form>
          </div>
          <br />
          {Boolean(this.state.tableList) && this.state.tableList.length > 0 ? (
            <>
              <Collapse activeKey={this.state.activePanelKey} onChange={this.setOpenPanels} accordion>
                {this.renderElement(this.state.tableList)}
              </Collapse>
              <br />
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <Button id="SaveAllButton" onClick={this.saveScoreAndWeight} style={{ width: "100%" }} variant="success">
                      <FormattedMessage id="GeneralButtonSaveAll" defaultMessage="Save All" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </>
          ) : (
            ""
          )}

          <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Are you sure?</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
                Cancel
              </Button>
              <Button variant="danger" onClick={this.delete}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </Badge.Ribbon>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ProcurementProgress);
