import React, { useState, useEffect } from "react";
import { handleRequest } from "../../ApiConnector";
import { error } from "../../MessageHelper";
import { Table, Col, Select, Row, DatePicker, Progress } from "antd";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import { Pie } from "@ant-design/plots";
import { Line } from "@ant-design/plots";
import { Column, Radar } from "@ant-design/charts";

const { Option } = Select;

const SalesmanCreditStatus = () => {
  const [tableList, setTableList] = useState([]);
  const [lineChartList, setLineChartList] = useState([]);
  const [pieChartList, setPieChartList] = useState([]);
  const [startDate, setStartDate] = useState(moment().subtract(6, "M"));
  const [endDate, setEndDate] = useState(moment());
  const [progressType, setProgressType] = useState("table");
  const [radarVisible, setRadarVisible] = useState(false);
  const [tableVisible, setTableVisible] = useState(true);
  const [pieVisible, setPieVisible] = useState(false);
  const [lineVisible, setLineVisible] = useState(false);
  const [columnVisible, setColumnVisible] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    showSizeChanger: true,
    pageSizeOptions: ["2", "5", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
    total: 0
  });

  useEffect(() => {
    asyncFetch();
  }, [startDate, endDate]);

  const asyncFetch = async () => {
    restartTable({ pagination }, progressType);
  };
  const handleTableChange = (pagination, filters, sorter) => {
    restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters }, progressType);
  };

  const restartTable = async (params = {}, progressType) => {
    const search = {
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize
    };
    console.log(search);

    var response = await handleRequest("POST", "/api/dashboard/salesmanCreditStatus/" + progressType, search);
    if (response.type === "ERROR") {
      error(response);
    } else {
      setTableList(Boolean(response.data.data) ? response.data.data : null);
      setPagination({
        ...params.pagination,
        total: response.data.totalRecords
      });
    }
    var responsePieChart = await handleRequest("POST", "/api/dashboard/salesmanCreditStatusPieChart", search);
    if (responsePieChart.type === "ERROR") {
      error(responsePieChart);
    } else {
      setPieChartList(Boolean(responsePieChart.data) ? responsePieChart.data : null);
    }
  };
  var pieConfig = {
    appendPadding: 10,
    data: pieChartList,
    angleField: "key",
    colorField: "value",
    radius: 1,
    innerRadius: 0.6,
    label: {
      type: "inner",
      offset: "-50%",
      content: "{value}",
      style: {
        textAlign: "center",
        fontSize: 14
      }
    },
    interactions: [
      {
        type: "element-selected"
      },
      {
        type: "element-active"
      },
      {
        type: "pie-statistic-active"
      }
    ]
    // statistic: {
    //   title: false,
    //   content: {
    //     offsetY: 4,
    //     style: {
    //       fontSize: '32px',
    //     },
    //     customHtml: (container, view, datum, data) => {

    //       const { width } = container.getBoundingClientRect();
    //       const text = datum ? `${datum.key}` : `${data.reduce((r, d) => r + d.key, 0)}`;
    //       return this.renderStatistic(width, text, {
    //         fontSize: 32,
    //       });
    //     },
    //   }
    // }
  };

  const barConfig = {
    data: pieChartList,
    isGroup: true,
    xField: "value",
    yField: "key",
    seriesField: "value",
    yAxis: {
      label: {
        formatter: v => `${v}`,
        layout: [{ type: "interval-adjust-position" }, { type: "interval-hide-overlap" }, { type: "adjust-color" }]
      }
    },
    color: ["#1979C9", "#D62A0D", "#FAA219", "#FF99C3"]
  };

  const lineConfig = {
    data: pieChartList,
    xField: "value",
    yField: "key",
    seriesField: "value",
    yAxis: {
      label: {
        formatter: v => `${v}`
      }
    },
    color: ["#1979C9", "#D62A0D", "#FAA219", "#FF99C3"]
  };

  const handleSelectChange = value => {
    switch (value) {
      case "table":
        setTableVisible(true);
        setRadarVisible(false);
        setPieVisible(false);
        setColumnVisible(false);
        setLineVisible(false);
        break;
      case "line":
        setLineVisible(true);
        setRadarVisible(false);
        setPieVisible(false);
        setColumnVisible(false);
        setTableVisible(false);
        break;

      case "progress":
        setTableVisible(true);
        setRadarVisible(false);
        setPieVisible(false);
        setColumnVisible(false);
        setLineVisible(false);
        break;

      case "circle":
        setTableVisible(true);
        setRadarVisible(false);
        setPieVisible(false);
        setColumnVisible(false);
        setLineVisible(false);
        break;
      case "radar":
        setRadarVisible(true);
        setPieVisible(false);
        setColumnVisible(false);
        setLineVisible(false);
        setTableVisible(false);
        break;
      case "pie":
        setPieVisible(true);
        setLineVisible(false);
        setColumnVisible(false);
        setTableVisible(false);
        setRadarVisible(false);
        break;
      case "column":
        setColumnVisible(true);
        setLineVisible(false);
        setTableVisible(false);
        setRadarVisible(false);
        setPieVisible(false);
        break;
    }
  };

  const columns = [
    {
      title: "",
      key: "type",
      render: record => {
        return record.type;
      }
    },

    {
      title: <FormattedMessage id="SalesmanCreditStatus.Number" defaultMessage="Number" />,
      key: "number",
      render: (value, row, index) => {
        if (progressType === "table") {
          return row.number;
        } else {
          return <Progress type={progressType} percent={row.number} status="active" strokeColor={{ from: "#108ee9", to: "#87d068" }} />;
        }
      },
      align: "center"
    },
    {
      title: <FormattedMessage id="SalesmanCreditStatus.TotalAmount" defaultMessage="Total Amount" />,
      key: "totalAmount",
      render: (value, row, index) => {
        if (progressType === "table") {
          return row.totalAmount;
        } else {
          return <Progress type={progressType} percent={row.totalAmount} status="active" strokeColor={{ from: "#108ee9", to: "#87d068" }} />;
        }
      },
      align: "center"
    },
    {
      title: <FormattedMessage id="SalesmanCreditStatus.NumberNotPaid" defaultMessage="Number Not Paid" />,
      key: "numberNotPaid",
      render: (value, row, index) => {
        if (progressType === "table") {
          return row.numberNotPaid;
        } else {
          return <Progress type={progressType} percent={row.numberNotPaid} status="active" strokeColor={{ from: "#108ee9", to: "#87d068" }} />;
        }
      },
      align: "center"
    },
    {
      title: <FormattedMessage id="SalesmanCreditStatus.TotalAmountNotPaid" defaultMessage="Total Amount Not Paid" />,
      key: "totalAmountNotPaid",
      render: (value, row, index) => {
        if (progressType === "table") {
          return row.totalAmountNotPaid;
        } else {
          return <Progress type={progressType} percent={row.totalAmountNotPaid} status="active" strokeColor={{ from: "#108ee9", to: "#87d068" }} />;
        }
      },
      align: "center"
    }
  ];

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col lg={4}></Col>
        <Col lg={6}>
          <DatePicker
            placeholder="Start Date"
            style={{ width: "100%" }}
            value={startDate}
            onChange={date => {
              setStartDate(date);
            }}
          />
        </Col>
        <Col lg={6}>
          <DatePicker
            placeholder="End Date"
            style={{ width: "100%" }}
            value={endDate}
            onChange={date => {
              setEndDate(date);
            }}
          />
        </Col>
        <Col lg={6}>
          <Select
            placeholder="Salesman Sales Type"
            defaultActiveFirstOption
            allowClear
            value={progressType}
            className="w-100"
            onChange={value => {
              setProgressType(value);
              restartTable({ pagination }, value);
              handleSelectChange(value);
            }}
          >
            <Option key={"dashboard"} value={"dashboard"}>
              <FormattedMessage id="SalesmanCreditStatus.Dashboard" defaultMessage="Dashboard" />
            </Option>
            <Option key={"circle"} value={"circle"}>
              <FormattedMessage id="SalesmanCreditStatus.Circle" defaultMessage="Circle" />
            </Option>
            <Option key={"table"} value={"table"}>
              <FormattedMessage id="SalesmanCreditStatus.Table" defaultMessage="Table" />
            </Option>
            <Option key={"pie"} value={"pie"}>
              <FormattedMessage id="SalesmanCreditStatus.Pie" defaultMessage="Pie" />
            </Option>
            <Option key={"column"} value={"column"}>
              <FormattedMessage id="SalesmanCreditStatus.Column" defaultMessage="Column" />
            </Option>
            <Option key={"radar"} value={"radar"}>
              <FormattedMessage id="SalesmanCreditStatus.Radar" defaultMessage="Radar" />
            </Option>
            <Option key={"line"} value={"line"}>
              <FormattedMessage id="SalesmanCreditStatus.Line" defaultMessage="Line" />
            </Option>
          </Select>
        </Col>
      </Row>  
      <Row gutter={[16, 16]}>
        <Col lg={24}>
          {tableVisible && <Table bordered columns={columns} dataSource={tableList} onChange={handleTableChange} pagination={pagination} />}
        </Col>
        <Col lg={24}>
          {Boolean(pieChartList) && pieChartList.length > 0 && pieVisible && <Pie {...pieConfig} />}
          {/* <PieChart apiUrl="/api/dashboard/monthly" detailHeader={false} /> */}

          {Boolean(pieChartList) && pieChartList.length > 0 && lineVisible && <Line {...lineConfig} />}

          {Boolean(pieChartList) && pieChartList.length > 0 && columnVisible && <Column {...barConfig} />}

          {Boolean(pieChartList) && pieChartList.length > 0 && radarVisible && <Radar {...barConfig} />}
        </Col>
      </Row>
    </>
  );
};

export default SalesmanCreditStatus;
