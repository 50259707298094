import "antd/dist/antd.css";
import React, { Component } from "react";
import { connect } from "react-redux";
import { API_BASE_ROOT, handleRequest } from "../../../ApiConnector";
import { error, showError, showSuccess } from "../../../MessageHelper";
import DeleteIcon from "@material-ui/icons/Delete";
import { FormattedMessage } from "react-intl";
import { Button } from "react-bootstrap";
import { Form, Table, Col, Row, Button as AntButton, Select, Input, InputNumber, Upload, Badge } from "antd";
import {
  BankOutlined,
  BookOutlined,
  ClockCircleOutlined,
  CommentOutlined,
  FileTextOutlined,
  GlobalOutlined,
  PhoneOutlined,
  ReadOutlined,
  SmileOutlined,
  TeamOutlined,
  UploadOutlined,
  UserOutlined
} from "@ant-design/icons";
import PhoneInput from "antd-phone-input/legacy";
import { fillFilterOperations, getColumnFilter } from "../../../Components/DataTableFilterComponent";
import { EditOutlined } from "@material-ui/icons";
import DynamicActionMenu from "../../CustomMenu/DynamicActionMenu";
import moment from "moment";
import ExcelReader from "../../excel/ExcelReader";
import CreateIcon from "@material-ui/icons/AddCircle";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { excelExport } from "../../../ExcelHelper";
import DraggableModal from "../../../Components/DraggableModal";
import PersonnelTraining from "../PersonnelTraining";
import PersonnelLogSaveFormFull from "../PersonnelLogSaveFormFull";
import PersonnelContract from "../PersonnelContract";
import PersonnelExperience from "../PersonnelExperience";
import PersonnelEducation from "../PersonnelEducation";
import PersonnelLanguage from "../PersonnelLanguage";
import PersonnelBankAccount from "../PersonnelBankAccount";
import PersonnelHobby from "../PersonnelHobby";
import PersonnelReference from "../PersonnelReference";
import PersonnelComment from "../PersonnelComment";
import PersonnelContact from "../PersonnelContact";

const { Option } = Select;

export class PersonnelLog extends Component {
  constructor(props) {
    super();
    this.state = {
      formName: "PersonnelLog",
      controllerName: "personnelLog",
      pageFormData: null,
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],
      fileList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],
      lastUpdateDate: [],
      restartTable: false,
      excelData: []
    };
  }
  formRef = React.createRef();
  componentDidMount = async () => {
    const { pagination } = this.state;
    if (Boolean(this.props) && Boolean(this.props.row)) {
      this.edit(this.props.row);
    }
    this.restartTable({ pagination });
    this.fillComboboxes();
  };
  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  // componentDidUpdate = async nextProps => {
  //   let id = Boolean(this.props) && Boolean(this.props.row) ? this.props.row.id : null;
  //   if (id === this.state.id) {
  //     return;
  //   }

  //   if (Boolean(this.props) && Boolean(this.props.row)) {
  //     this.edit(this.props.row);
  //   } else {
  //     this.resetInputs();
  //   }
  // };

  fillComboboxes = async () => {
    fillFilterOperations();
    var responseGenderTypes = await handleRequest("GET", "/api/" + this.state.controllerName + "/genderTypes");
    if (responseGenderTypes.type === "ERROR") {
      error(responseGenderTypes);
    }
    this.setState({
      genderSelectItems: Boolean(responseGenderTypes.data) ? responseGenderTypes.data : []
    });

    var responsePersonnelTypeTypes = await handleRequest("GET", "/api/" + this.state.controllerName + "/personnelTypeTypes");
    if (responsePersonnelTypeTypes.type === "ERROR") {
      error(responsePersonnelTypeTypes);
    }
    this.setState({
      personnelTypeSelectItems: Boolean(responsePersonnelTypeTypes.data) ? responsePersonnelTypeTypes.data : []
    });

    var responseCountryType = await handleRequest("GET", "/api/" + this.state.controllerName + "/countryType");
    if (responseCountryType.type === "ERROR") {
      error(responseCountryType);
    }
    this.setState({
      nationalityIdSelectItems: Boolean(responseCountryType.data) ? responseCountryType.data : []
    });

    var responseBloodGroupTypes = await handleRequest("GET", "/api/" + this.state.controllerName + "/bloodGroupTypes");
    if (responseBloodGroupTypes.type === "ERROR") {
      error(responseBloodGroupTypes);
    }
    this.setState({
      bloodGroupSelectItems: Boolean(responseBloodGroupTypes.data) ? responseBloodGroupTypes.data : []
    });

    var responseMaritalStatusTypes = await handleRequest("GET", "/api/" + this.state.controllerName + "/maritalStatusTypes");
    if (responseMaritalStatusTypes.type === "ERROR") {
      error(responseMaritalStatusTypes);
    }
    this.setState({
      maritalStatusSelectItems: Boolean(responseMaritalStatusTypes.data) ? responseMaritalStatusTypes.data : []
    });

    var responseMilitaryObligationTypes = await handleRequest("GET", "/api/" + this.state.controllerName + "/militaryObligationTypes");
    if (responseMilitaryObligationTypes.type === "ERROR") {
      error(responseMilitaryObligationTypes);
    }
    this.setState({
      militaryObligationSelectItems: Boolean(responseMilitaryObligationTypes.data) ? responseMilitaryObligationTypes.data : []
    });
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
    if (Boolean(this.props) && Boolean(this.props.closeModal)) {
      this.props.closeModal();
    }
    if (Boolean(this.props) && Boolean(this.props.onCancel)) {
      this.props.onCancel();
    }
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,
      fileList: []
    });
  };
  getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });

    if (Boolean(this.props) && Boolean(this.props.onEdit)) {
      let obj = {
        id: row.id,
        name: row.name + " " + (Boolean(row.midName) ? row.midName : "") + " " + row.lastName
      };
      if (Boolean(row.id)) {
        obj.id = row.id;
      }
      this.props.onEdit(obj);
      document.getElementById("kt_scrolltop").click();
    }
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/personnelContact/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hiddenSave: false,
        hiddenUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hiddenSave: true,
        hiddenUpdate: false
      });
    }
  };

  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id,
      phoneNumber: values.phoneNumber.countryCode + " " + values.phoneNumber.areaCode + " " + values.phoneNumber.phoneNumber
    };

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      if (Boolean(this.props) && Boolean(this.props.onSave)) {
        let obj = {
          id: response.data,
          name: newItem.name + " " + (Boolean(newItem.midName) ? newItem.midName : "") + " " + newItem.lastName
        };
        if (Boolean(newItem.id)) {
          obj.id = newItem.id;
        }
        this.props.onSave(obj);
        document.getElementById("kt_scrolltop").click();
      }
    }
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };
  normalizingFileUpload = event => {
    if (Array.isArray(event)) {
      return event;
    }
    return event && event.fileList;
  };

  action = record => {
    const actions = [
      { name: "Edit", icon: <EditOutlined fontSize="small" color="primary" />, actionClick: this.edit.bind(this) },
      { name: "Personal Information", icon: <UserOutlined fontSize="small" style={{ border: "0px", color: "#1BC5BD" }} />, actionClick: this.personalInformation.bind(this) },
      { name: "Contacts", icon: <PhoneOutlined fontSize="small" style={{ border: "0px", color: "#007bff" }} />, actionClick: this.contacts.bind(this) },
      { name: "Trainings", icon: <ReadOutlined fontSize="small" style={{ border: "0px", color: "#1BC5BD" }} />, actionClick: this.trainings.bind(this) },
      { name: "Contract", icon: <FileTextOutlined fontSize="small" style={{ border: "0px", color: "#007bff" }} />, actionClick: this.contract.bind(this) },
      { name: "Experiences", icon: <ClockCircleOutlined fontSize="small" style={{ border: "0px", color: "#1BC5BD" }} />, actionClick: this.experiences.bind(this) },
      { name: "Education", icon: <BookOutlined fontSize="small" style={{ border: "0px", color: "#1BC5BD" }} />, actionClick: this.education.bind(this) },
      { name: "Language Skills", icon: <GlobalOutlined fontSize="small" style={{ border: "0px", color: "#1BC5BD" }} />, actionClick: this.languageSkills.bind(this) },
      { name: "Bank Accounts", icon: <BankOutlined fontSize="small" style={{ border: "0px", color: "#007bff" }} />, actionClick: this.bankAccounts.bind(this) },
      { name: "Hobbies", icon: <SmileOutlined fontSize="small" style={{ border: "0px", color: "#1BC5BD" }} />, actionClick: this.hobbies.bind(this) },
      { name: "References", icon: <TeamOutlined fontSize="small" style={{ border: "0px", color: "#007bff" }} />, actionClick: this.references.bind(this) },
      { name: "Comments", icon: <CommentOutlined fontSize="small" style={{ border: "0px", color: "#1BC5BD" }} />, actionClick: this.comments.bind(this) },
      { name: "Delete", icon: <DeleteIcon fontSize="small" color="error" />, actionClick: this.deleteModal.bind(this) }
    ];

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actions} />
      </React.Fragment>
    );
  };

  personalInformation = row => {
    this.setState({
      modalPersonalInformationShow: true,
      id: row.id,
      currentPersonnelName: row.name,
      currentRow: row
    });
  };

  contacts = row => {
    this.setState({
      modalContactsShow: true,
      id: row.id,
      currentPersonnelName: row.name
    });
  };

  trainings = row => {
    this.setState({
      modalTraningsShow: true,
      id: row.id,
      currentPersonnelName: row.name
    });
  };

  contract = row => {
    this.setState({
      modalContractShow: true,
      id: row.id,
      currentPersonnelName: row.name
    });
  };

  experiences = row => {
    this.setState({
      modalExperiencesShow: true,
      id: row.id,
      currentPersonnelName: row.name
    });
  };

  education = row => {
    this.setState({
      modalEducationShow: true,
      id: row.id,
      currentPersonnelName: row.name
    });
  };

  languageSkills = row => {
    this.setState({
      modalLanguageSkillsShow: true,
      id: row.id,
      currentPersonnelName: row.name
    });
  };

  bankAccounts = row => {
    this.setState({
      modalBankAccountsShow: true,
      id: row.id,
      currentPersonnelName: row.name
    });
  };

  hobbies = row => {
    this.setState({
      modalHobbiesShow: true,
      id: row.id,
      currentPersonnelName: row.name
    });
  };

  references = row => {
    this.setState({
      modalReferencesShow: true,
      id: row.id,
      currentPersonnelName: row.name
    });
  };

  comments = row => {
    this.setState({
      modalCommentsShow: true,
      id: row.id,
      currentPersonnelName: row.name
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 },
      style: { marginBottom: 0 }
    };

    const columns = [
      {
        key: "key",
        render: this.action
      },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="PersonnelLog.PhoneNumber" defaultMessage="Budge No" />,
        key: "budgeNo",
        render: record => {
          return record.budgeNo;
        },
        ...getColumnFilter({
          inputName: "budgeNo",
          inputType: "NUMBER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="PersonnelLog.ContactType" defaultMessage="Personnel Type" />,
        key: "personnelType",
        render: record => {
          return record.personnelType;
        },
        ...getColumnFilter({
          inputName: "personnelType",
          inputType: "SELECT",
          selectItems:
            Boolean(this.state["personnelTypeSelectItems"]) && this.state["personnelTypeSelectItems"].length > 0
              ? this.state["personnelTypeSelectItems"]
              : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="PersonnelLog.ContactType" defaultMessage="Gender" />,
        key: "gender",
        render: record => {
          return record.gender;
        },
        ...getColumnFilter({
          inputName: "gender",
          inputType: "SELECT",
          selectItems: Boolean(this.state["genderSelectItems"]) && this.state["genderSelectItems"].length > 0 ? this.state["genderSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="PersonnelLog.Adress" defaultMessage="Photo" />,
        key: "photo",
        render: record => {
          return record.photo;
        },
        ...getColumnFilter({
          inputName: "photo",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="PersonnelLog.Nationality" defaultMessage="Nationality" />,
        key: "nationalityId",
        render: record => {
          return record.nationalityName;
        },
        ...getColumnFilter({
          inputName: "nationalityName",
          inputType: "SELECT",
          selectItems:
            Boolean(this.state["nationalityIdSelectItems"]) && this.state["nationalityIdSelectItems"].length > 0
              ? this.state["nationalityIdSelectItems"]
              : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="PersonnelLog.Adress" defaultMessage="Name" />,
        key: "name",
        render: record => {
          return record.name;
        },
        ...getColumnFilter({
          inputName: "name",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="PersonnelLog.Adress" defaultMessage="Mid Name" />,
        key: "midName",
        render: record => {
          return record.midName;
        },
        ...getColumnFilter({
          inputName: "midName",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="PersonnelLog.Adress" defaultMessage="Last Name" />,
        key: "lastName",
        render: record => {
          return record.lastName;
        },
        ...getColumnFilter({
          inputName: "lastName",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="PersonnelLog.Adress" defaultMessage="Phone Number" />,
        key: "phoneNumber",
        render: record => {
          return record.phoneNumber;
        },
        ...getColumnFilter({
          inputName: "phoneNumber",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="PersonnelLog.Adress" defaultMessage="Email" />,
        key: "email",
        render: record => {
          return record.email;
        },
        ...getColumnFilter({
          inputName: "email",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },

      {
        title: <FormattedMessage id="PersonnelLog.Adress" defaultMessage="Father Name" />,
        key: "fatherName",
        render: record => {
          return record.fatherName;
        },
        ...getColumnFilter({
          inputName: "fatherName",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },

      {
        title: <FormattedMessage id="PersonnelLog.Adress" defaultMessage="Mother Name" />,
        key: "motherName",
        render: record => {
          return record.motherName;
        },
        ...getColumnFilter({
          inputName: "motherName",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },

      {
        title: <FormattedMessage id="PersonnelLog.Adress" defaultMessage="Place Of Birth" />,
        key: "placeOfBirth",
        render: record => {
          return record.placeOfBirth;
        },
        ...getColumnFilter({
          inputName: "placeOfBirth",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="PersonnelLog.lastDate" defaultMessage="Date Of Birth" />,
        key: "dateOfBirth",
        render: record => {
          return moment(record.dateOfBirth).format("DD-MM-YYYY HH:mm");
        },
        ...getColumnFilter({
          inputName: "dateOfBirth",
          inputType: "DATEPICKER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="PersonnelLog.PhoneNumber" defaultMessage="Identification No" />,
        key: "identificationNo",
        render: record => {
          return record.identificationNo;
        },
        ...getColumnFilter({
          inputName: "identificationNo",
          inputType: "NUMBER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="PersonnelLog.lastDate" defaultMessage="Identification Expiry Date" />,
        key: "identificationExpiryDate",
        render: record => {
          return moment(record.identificationExpiryDate).format("DD-MM-YYYY HH:mm");
        },
        ...getColumnFilter({
          inputName: "identificationExpiryDate",
          inputType: "DATEPICKER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="PersonnelLog.Adress" defaultMessage="Identification" />,
        key: "identificationPath",
        render: record => {
          return record.identificationPath;
        },
        ...getColumnFilter({
          inputName: "identificationPath",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="PersonnelLog.PhoneNumber" defaultMessage="Driving License No" />,
        key: "drivingLicenseNo",
        render: record => {
          return record.drivingLicenseNo;
        },
        ...getColumnFilter({
          inputName: "drivingLicenseNo",
          inputType: "NUMBER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="PersonnelLog.lastDate" defaultMessage="Driving License Expiry Date" />,
        key: "drivingLicenseExpiryDate",
        render: record => {
          return moment(record.drivingLicenseExpiryDate).format("DD-MM-YYYY HH:mm");
        },
        ...getColumnFilter({
          inputName: "drivingLicenseExpiryDate",
          inputType: "DATEPICKER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="PersonnelLog.Adress" defaultMessage="Driving License" />,
        key: "drivingLicensePath",
        render: record => {
          return record.drivingLicensePath;
        },
        ...getColumnFilter({
          inputName: "drivingLicensePath",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="PersonnelLog.PhoneNumber" defaultMessage="Passport No" />,
        key: "passportNo",
        render: record => {
          return record.passportNo;
        },
        ...getColumnFilter({
          inputName: "passportNo",
          inputType: "NUMBER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="PersonnelLog.lastDate" defaultMessage="Passport Expiry Date" />,
        key: "passportExpiryDate",
        render: record => {
          return moment(record.passportExpiryDate).format("DD-MM-YYYY HH:mm");
        },
        ...getColumnFilter({
          inputName: "passportExpiryDate",
          inputType: "DATEPICKER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="PersonnelLog.Adress" defaultMessage="Passport" />,
        key: "passportPath",
        render: record => {
          return record.passportPath;
        },
        ...getColumnFilter({
          inputName: "passportPath",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="PersonnelLog.BloodGroup" defaultMessage="Blood Group" />,
        key: "bloodGroup",
        render: record => {
          return record.bloodGroupName;
        },
        ...getColumnFilter({
          inputName: "bloodGroup",
          inputType: "SELECT",
          selectItems:
            Boolean(this.state["bloodGroupSelectItems"]) && this.state["bloodGroupSelectItems"].length > 0 ? this.state["bloodGroupSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="PersonnelLog.MaritalStatus" defaultMessage="Marital Status" />,
        key: "maritalStatus",
        render: record => {
          return record.maritalStatusName;
        },
        ...getColumnFilter({
          inputName: "maritalStatus",
          inputType: "SELECT",
          selectItems:
            Boolean(this.state["maritalStatusSelectItems"]) && this.state["maritalStatusSelectItems"].length > 0
              ? this.state["maritalStatusSelectItems"]
              : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="PersonnelLog.PhoneNumber" defaultMessage="Number Of Children" />,
        key: "numberOfChildren",
        render: record => {
          return record.numberOfChildren;
        },
        ...getColumnFilter({
          inputName: "numberOfChildren",
          inputType: "NUMBER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="PersonnelLog.Country" defaultMessage="Military Obligation" />,
        key: "militaryObligation",
        render: record => {
          return record.militaryObligationName;
        },
        ...getColumnFilter({
          inputName: "militaryObligation",
          inputType: "SELECT",
          selectItems:
            Boolean(this.state["militaryObligationSelectItems"]) && this.state["militaryObligationSelectItems"].length > 0
              ? this.state["militaryObligationSelectItems"]
              : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="PersonnelLog.Adress" defaultMessage="Health Problems" />,
        key: "healthProblems",
        render: record => {
          return record.healthProblems;
        },
        ...getColumnFilter({
          inputName: "healthProblems",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      }
    ];

    const props = {
      action: `${API_BASE_ROOT}/File/upload`,
      listType: "picture",
      defaultFileList: [...this.state.fileList]
    };

    const columnsExcel = [{ header: "Name", key: "name" }];

    return (
      <Badge.Ribbon
        text={<FormattedMessage id="PersonnelLog.pageTitle" defaultMessage="Personnel Registration" />}
        placement="start"
        color="volcano"
        style={{ fontSize: 12 }}
      >
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <Row gutter={[16, 16]}>
              <Col md={21}></Col>
              <Col md={1}>
                <AntButton
                  style={{ border: "0px" }}
                  onClick={() => {
                    excelExport(columnsExcel, this.state.tableList);
                  }}
                  icon={<ExportIcon color="action" fontSize="large" />}
                />
              </Col>
              <Col md={1}>
                <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>
              </Col>
              <Col md={1}>
                <AntButton
                  style={{ border: "0px" }}
                  hidden={!this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<CreateIcon color="secondary" fontSize="large" />}
                ></AntButton>
                <AntButton
                  style={{ border: "0px" }}
                  hidden={this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<RemoveCircle color="error" fontSize="large" />}
                ></AntButton>
              </Col>
            </Row>
            <div hidden={this.state.hideInputs}>
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="PersonnelLog.BudgeNo" defaultMessage="Budge No" />}
                  name="budgeNo"
                  rules={[
                    {
                      required: true,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    min={0}
                    parser={value => value.replace(",", ".")}
                    formatter={value => value.replace(".", ",")}
                  />
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="PersonnelLog.PersonnelType" defaultMessage="Personnel Type" />}
                  name="personnelType"
                  rules={[
                    {
                      required: true,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                    {Boolean(this.state["personnelTypeSelectItems"]) &&
                      this.state["personnelTypeSelectItems"].length > 0 &&
                      this.state["personnelTypeSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              }

              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="PersonnelLog.Gender" defaultMessage="Gender" />}
                  name="gender"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                    {Boolean(this.state["genderSelectItems"]) &&
                      this.state["genderSelectItems"].length > 0 &&
                      this.state["genderSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              }

              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="PersonnelLog.Photo" defaultMessage="Photo" />}
                  name="photoPath"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Upload
                    {...props}
                    showUploadList={true}
                    fileList={this.state.fileList}
                    onChange={({ fileList }) => {
                      if (fileList.length === 1) {
                        if (Boolean(fileList[0].response)) {
                          fileList[0].url = fileList[0].response.url;
                          showSuccess("file uploaded successfully");
                        }
                        this.setState({
                          fileList: fileList
                        });
                      }
                    }}
                    beforeUpload={fileList => {
                      if (this.state.fileList.length > 0) {
                        showError("You have been already choose a file! For change firstly remove the uploaded file.");
                        return false;
                      }
                      return true;
                    }}
                    onRemove={fileList => {
                      this.setState({
                        fileList: []
                      });
                    }}
                  >
                    <AntButton style={{ width: "100%", marginTop: "0.2rem" }}>
                      <UploadOutlined /> Upload
                    </AntButton>
                  </Upload>
                </Form.Item>
              }

              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="PersonnelLog.Nationality" defaultMessage="Nationality" />}
                  name="nationalityId"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                    {Boolean(this.state["nationalityIdSelectItems"]) &&
                      this.state["nationalityIdSelectItems"].length > 0 &&
                      this.state["nationalityIdSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              }

              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="PersonnelLog.Name" defaultMessage="Name" />}
                  name="name"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              }

              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="PersonnelLog.MidName" defaultMessage="Mid Name" />}
                  name="midName"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              }

              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="PersonnelLog.LastName" defaultMessage="Last Name" />}
                  name="lastName"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              }

              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="PersonnelLog.PhoneNumber" defaultMessage="Phone Number" />}
                  name="phoneNumber"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <PhoneInput enableSearch inputStyle={{ width: "100%" }} />
                </Form.Item>
              }

              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="PersonnelLog.Email" defaultMessage="Email" />}
                  name="email"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              }

              <div hidden={this.state.hideSave}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                        <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
              <div hidden={this.state.hideUpdate}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                        <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                      </Button>
                    </Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                        <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
            </div>

            <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
              <Table
                bordered
                scroll={{ x: 900, scrollToFirstRowOnChange: true }}
                columns={columns}
                dataSource={this.state.tableList}
                loading={this.state.loading}
                onChange={this.handleTableChange}
                pagination={this.state.pagination}
              />
            </div>
          </Form>
        </div>

        <DraggableModal
          title={<FormattedMessage id="PersonnelLog.PersonalInformation" defaultMessage="Personal Information" />}
          visible={this.state.modalPersonalInformationShow}
          okButtonProps={{ hidden: true }}
          onCancel={() => this.setState({ modalPersonalInformationShow: false })}
          width={1400}
          content={
            <PersonnelLogSaveFormFull
              row={this.state.currentRow}
              isPrompt={false}
              onSave={() => {
                this.setState({
                  id: this.state.currentRow.id,
                  currentPersonnelName: this.state.currentRow.name,
                  isPersonnelSaveModalVisible: false
                });
                this.restartTable();
              }}
              onCancel={this.onClickCreateNew.bind(this)}
            />
          }
        />

        <DraggableModal
          title={<FormattedMessage id="PersonnelLog.Contacts" defaultMessage="Contacts" />}
          visible={this.state.modalContactsShow}
          okButtonProps={{ hidden: true }}
          onCancel={() => this.setState({ modalContactsShow: false })}
          width={1400}
          content={<PersonnelContact personnelId={this.state.id} personnelName={this.state.currentPersonnelName} />}
        />

        <DraggableModal
          title={<FormattedMessage id="PersonnelLog.Tranings" defaultMessage="Tranings" />}
          visible={this.state.modalTraningsShow}
          okButtonProps={{ hidden: true }}
          onCancel={() => this.setState({ modalTraningsShow: false })}
          width={1400}
          content={<PersonnelTraining personnelId={this.state.id} personnelName={this.state.currentPersonnelName} />}
        />

        <DraggableModal
          title={<FormattedMessage id="PersonnelLog.Contract" defaultMessage="Contract" />}
          visible={this.state.modalContractShow}
          okButtonProps={{ hidden: true }}
          onCancel={() => this.setState({ modalContractShow: false })}
          width={1400}
          content={<PersonnelContract personnelId={this.state.id} personnelName={this.state.currentPersonnelName} />}
        />

        <DraggableModal
          title={<FormattedMessage id="PersonnelLog.Experience" defaultMessage="Experience" />}
          visible={this.state.modalExperiencesShow}
          okButtonProps={{ hidden: true }}
          onCancel={() => this.setState({ modalExperiencesShow: false })}
          width={1400}
          content={<PersonnelExperience personnelId={this.state.id} personnelName={this.state.currentPersonnelName} />}
        />

        <DraggableModal
          title={<FormattedMessage id="PersonnelLog.Education" defaultMessage="Education" />}
          visible={this.state.modalEducationShow}
          okButtonProps={{ hidden: true }}
          onCancel={() => this.setState({ modalEducationShow: false })}
          width={1400}
          content={<PersonnelEducation personnelId={this.state.id} personnelName={this.state.currentPersonnelName} />}
        />

        <DraggableModal
          title={<FormattedMessage id="PersonnelLog.LanguageSkills" defaultMessage="Language Skills" />}
          visible={this.state.modalLanguageSkillsShow}
          okButtonProps={{ hidden: true }}
          onCancel={() => this.setState({ modalLanguageSkillsShow: false })}
          width={1400}
          content={<PersonnelLanguage personnelId={this.state.id} personnelName={this.state.currentPersonnelName} />}
        />

        <DraggableModal
          title={<FormattedMessage id="PersonnelLog.BankAccount" defaultMessage="Bank Account" />}
          visible={this.state.modalBankAccountsShow}
          okButtonProps={{ hidden: true }}
          onCancel={() => this.setState({ modalBankAccountsShow: false })}
          width={1400}
          content={<PersonnelBankAccount personnelId={this.state.id} personnelName={this.state.currentPersonnelName} />}
        />

        <DraggableModal
          title={<FormattedMessage id="PersonnelLog.Hobbies" defaultMessage="Hobbies" />}
          visible={this.state.modalHobbiesShow}
          okButtonProps={{ hidden: true }}
          onCancel={() => this.setState({ modalHobbiesShow: false })}
          width={1400}
          content={<PersonnelHobby personnelId={this.state.id} personnelName={this.state.currentPersonnelName} />}
        />

        <DraggableModal
          title={<FormattedMessage id="PersonnelLog.Reference" defaultMessage="Reference" />}
          visible={this.state.modalReferencesShow}
          okButtonProps={{ hidden: true }}
          onCancel={() => this.setState({ modalReferencesShow: false })}
          width={1400}
          content={<PersonnelReference personnelId={this.state.id} personnelName={this.state.currentPersonnelName} />}
        />

        <DraggableModal
          title={<FormattedMessage id="PersonnelLog.Comment" defaultMessage="Comment" />}
          visible={this.state.modalCommentsShow}
          okButtonProps={{ hidden: true }}
          onCancel={() => this.setState({ modalCommentsShow: false })}
          width={1400}
          content={<PersonnelComment personnelId={this.state.id} personnelName={this.state.currentPersonnelName} />}
        />
      </Badge.Ribbon>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PersonnelLog);
