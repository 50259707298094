import { FileImageOutlined, FilePdfOutlined, FullscreenOutlined } from "@ant-design/icons";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import { Button as AntButton, Checkbox, Form, Tooltip } from "antd";
import "antd/dist/antd.css";
import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { handleRequest } from "../../ApiConnector";
import avatarPersonnel from "../../assets/img/avatar-personnel.svg";
import OrgChart from "../../Components/react-org-chart";
import { error, showError, showSuccess } from "../../MessageHelper";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import SettingsIcon from "@material-ui/icons/Settings";
import DraggableModal from "../../Components/DraggableModal";
import { OrderList } from "primereact/orderlist";
import SortIcon from "@material-ui/icons/Sort";

//For downloading org chart as image or pdf based on id
const downloadImageId = "download-image";
const downloadPdfId = "download-pdf";
const zoomExtentId = "zoom-extend";
export class OrganizationalChart extends Component {
  constructor() {
    super();
    this.state = {
      formName: "OrganizationalChart",
      controllerName: "organizationalChart",
      modalDeleteShow: false,
      id: null,
      list: []
    };
  }

  formRefSettings = React.createRef();

  componentDidMount = async () => {
    let chartd = await this.getChartData(0);
    this.tree.person.totalReports = chartd.length;
    this.tree.children.push(...chartd);
    this.setState({
      chartData: this.tree
    });
  };

  getChartData = async departmentId => {
    let url = "/api/personnelLog/organizationalChart/" + departmentId;
    const response = await handleRequest("GET", url);
    if (response.type === "ERROR") {
      showError(response.message);
      this.setState({
        list: []
      });
      return [];
    } else {
      let list = [];
      if (Boolean(response.data) && response.data.length > 0) {
        response.data.forEach(element => {
          if (element.person) {
            list.push({
              ...element.person,
              departmentId: element.departmentId
            });
          }
        });
      }
      console.log("list", list);
      this.setState({
        list: list
      });
      return response.data;
    }
  };

  tree = {
    id: 0,
    person: {
      id: 0,
      avatar: avatarPersonnel,
      name: "Company Name",
      title: "",
      totalReports: 3
    },
    hasChild: true,
    hasParent: false,
    children: []
  };

  getChild = async id => {
    let chartd = await this.getChartData(id);
    return chartd;
  };

  getParent = d => {
    if (d.id === 100) {
    }
  };

  handleDownload = () => {
    this.setState({ downloadingChart: false });
  };

  handleOnChangeConfig = config => {
    this.setState({ config: config });
  };

  handleLoadConfig = () => {
    const { config } = this.state;
    return config;
  };

  action = record => {
    return (
      <React.Fragment>
        <DynamicActionMenu
          row={record}
          actionList={[
            {
              name: "Edit",
              icon: <EditOutlined fontSize="small" color="primary" />,
              actionClick: this.edit.bind(this)
            },
            {
              name: "Delete",
              icon: <DeleteIcon fontSize="small" color="error" />,
              actionClick: this.deleteModal.bind(this)
            }
          ]}
        />
      </React.Fragment>
    );
  };

  saveSettings = async values => {
    const newItem = {
      ...values
    };
    var response = await handleRequest("POST", "/api/organizationalChartSettings/saveSettings", newItem);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.setState({
        modalSettingsShow: false
      });
      this.getChartData(0);
      showSuccess();
    }
  };

  saveOrder = async () => {
    const list = this.state.list;
    list.forEach((element, i) => {
      element.orderNo = i;
    });
    var response = await handleRequest("PUT", "/api/organizationalChartSettings/order", list);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.setState({
        modalOrderShow: false
      });
    }
  };

  template(item) {
    return (
      <div className="p-clearfix">
        <div style={{ fontSize: "12px", margin: "5px 5px 0 0" }}>{item.name}</div>
      </div>
    );
  }

  render() {
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 },
      style: {
        marginBottom: 0
      }
    };

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <div className="row">
          <div className="col-md-12 d-flex">
            <span className="h3 flex-grow-1">
              <FormattedMessage id={this.state.controllerName + ".pageTitle"} defaultMessage={"Organizational Chart"} />
            </span>
            <AntButton
              style={{ border: "0px" }}
              onClick={() => {
                this.setState({
                  modalOrderShow: true
                });
              }}
              icon={<SortIcon fontSize="large" color="secondary" />}
            ></AntButton>
            <Tooltip title={<FormattedMessage id="OrganizationalChart.Settings" defaultMessage="Settings" />}>
              <AntButton
                style={{ border: "0px" }}
                onClick={() => {
                  this.setState(
                    {
                      modalSettingsShow: true
                    },
                    async () => {
                      const response = await handleRequest("GET", "/api/organizationalChartSettings/getSettings");
                      if (response.type === "ERROR") {
                      } else {
                        if (Boolean(response.data)) {
                          this.formRefSettings.current.setFieldsValue({
                            ...response.data
                          });
                        } else {
                          this.formRefSettings.current.resetFields();
                        }
                      }
                    }
                  );
                }}
                icon={<SettingsIcon color="error" fontSize="large" />}
              ></AntButton>
            </Tooltip>
            <AntButton style={{ border: "0px" }} id={zoomExtentId} icon={<FullscreenOutlined style={{ fontSize: "23px", color: "#08c" }} />}>
              {/* Fit to screen */}
            </AntButton>
            <AntButton style={{ border: "0px" }} id={downloadImageId} icon={<FileImageOutlined style={{ fontSize: "23px", color: "#08c" }} />}>
              {/* Save As Image */}
            </AntButton>
            <AntButton style={{ border: "0px" }} id={downloadPdfId} icon={<FilePdfOutlined style={{ fontSize: "23px", color: "#08c" }} />}>
              {/* Save As PDF */}
            </AntButton>
          </div>
        </div>

        <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
          {this.state.chartData ? (
            <>
              <OrgChart
                tree={this.tree}
                downloadImageId={downloadImageId}
                downloadPdfId={downloadPdfId}
                zoomExtentId={zoomExtentId}
                onConfigChange={config => {
                  this.handleOnChangeConfig(config);
                }}
                loadConfig={d => {
                  let configuration = this.handleLoadConfig(d);
                  if (configuration) {
                    return configuration;
                  }
                }}
                downlowdedOrgChart={d => {
                  this.handleDownload();
                }}
                loadImage={d => {
                  return Promise.resolve(avatarPersonnel);
                }}
                // loadParent={d => {
                //   const parentData = this.getParent(d);
                //   return parentData;
                // }}
                loadChildren={d => {
                  const childrenData = this.getChild(d.departmentId);
                  return childrenData;
                }}
              />
            </>
          ) : null}
        </div>

        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.delete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        <DraggableModal
          title={<FormattedMessage id="OrganizationalChart.Settings" defaultMessage="Settings" />}
          open={this.state.modalSettingsShow}
          onCancel={() => {
            this.setState({
              modalSettingsShow: false
            });
          }}
          onOk={() => {
            this.formRefSettings.current.submit();
          }}
          width={800}
          centered
          cancelButtonProps={{ hidden: false }}
          okButtonProps={{ hidden: false }}
          content={
            <Form initialValues={{ remember: false }} onFinish={this.saveSettings} ref={this.formRefSettings}>
              <Form.Item
                {...layout}
                label={<FormattedMessage id="OrganizationalChart.Name" defaultMessage="Name" />}
                name="name"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                valuePropName="checked"
              >
                <Checkbox />
              </Form.Item>

              <Form.Item
                {...layout}
                label={<FormattedMessage id="OrganizationalChart.Designation" defaultMessage="Designation" />}
                name="designation"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                valuePropName="checked"
              >
                <Checkbox />
              </Form.Item>

              <Form.Item
                {...layout}
                label={<FormattedMessage id="OrganizationalChart.Phone" defaultMessage="Phone" />}
                name="phone"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                valuePropName="checked"
              >
                <Checkbox />
              </Form.Item>

              <Form.Item
                {...layout}
                label={<FormattedMessage id="OrganizationalChart.Email" defaultMessage="Email" />}
                name="email"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                valuePropName="checked"
              >
                <Checkbox />
              </Form.Item>

              <Form.Item
                {...layout}
                label={<FormattedMessage id="OrganizationalChart.Photo" defaultMessage="Photo" />}
                name="photo"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                valuePropName="checked"
              >
                <Checkbox />
              </Form.Item>
            </Form>
          }
        />

        <DraggableModal
          title={<FormattedMessage id="StockCardPurchaseCriteria.Order" defaultMessage="Order" />}
          centered
          width={600}
          okButtonProps={{ hidden: false }}
          open={this.state.modalOrderShow}
          onCancel={() => {
            this.setState({ modalOrderShow: false });
          }}
          onOk={() => {
            this.saveOrder();
          }}
          content={
            <>
              {console.log(this.state.list)}
              {Boolean(this.state.list) && this.state.list.length > 0 && (
                <>
                  <OrderList
                    value={this.state.list}
                    responsive={true}
                    itemTemplate={this.template}
                    onChange={e => this.setState({ list: e.value })}
                  ></OrderList>
                </>
              )}
            </>
          }
        />
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationalChart);
