import React, { Component } from "react";
import { connect } from "react-redux";
import { handleRequest } from "../../ApiConnector";
import CustomMaterialMenu from "../CustomMenu/CustomMaterialMenu";
import { Button, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Form, Table, Select, Col, Row } from "antd";
import "antd/dist/antd.css";
import { error, showSuccess } from "../../MessageHelper";

const { Option } = Select;

export class UserCompany extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      userId: null,
      selectedUserIds: [],
      userSelectItems: [],
      companySelectItemms: [],
      selectedCompanyIds: [],
      projectSelectItems: [],
      selectedProjectIds: [],

      tableList: [],
      hideUpdate: true,
      hideSave: false
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.fillComboboxes();
    this.restartTable();
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.setFieldsValue({
      selectedUserIds: [],
      selectedCompanyIds: [],
      selectedProjectIds: []
    });

    this.setState({
      hideUpdate: true,
      hideSave: false,
      userId: null,
      selectedUserIds: [],
      selectedCompanyIds: [],
      selectedProjectIds: []
    });
  };

  edit = row => {
    var folderIds = [];
    var userIds = [],
      companyIds = [],
      projectIds = [];
    userIds.push(row.id);
    if (Boolean(row.folderList) && row.folderList.length > 0)
      row.folderList.forEach(element => {
        folderIds.push(element.folderId);
      });
    if (Boolean(row.projectList) && row.projectList.length > 0)
      row.projectList.forEach(element => {
        projectIds.push(element.projectId);
      });

    if (Boolean(row.companyList) && row.companyList.length > 0)
      row.companyList.forEach(element => {
        companyIds.push(element.companyId);
      });

    this.formRef.current.setFieldsValue({
      selectedUserIds: userIds,
      selectedCompanyIds: companyIds,
      selectedProjectIds: projectIds
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,

      selectedUserIds: userIds,
      selectedCompanyIds: companyIds,
      selectedProjectIds: projectIds
    });
  };

  actionTemplate(row) {
    return (
      <React.Fragment>
        <CustomMaterialMenu row={row} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
      </React.Fragment>
    );
  }

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      userId: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.userId
    };

    var response = await handleRequest("DELETE", "/api/userFolders/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.restartTable();
      showSuccess();
      this.setState({
        companyId: null,
        modalDeleteShow: false
      });
    }
  };

  restartTable = async () => {
    let url = "/api/users/hasCompany";

    const response = await handleRequest("GET", url);

    if (response.type === "ERROR") {
      error(response);
    } else
      this.setState({
        tableList: Boolean(response.data) ? response.data : []
      });
  };

  fillComboboxes = async () => {
    let url2 = "/api/users/company";
    // let url2 = "/api/users/authorized";

    const response2 = await handleRequest("GET", url2);

    if (response2.type === "ERROR") {
      error(response2);
    } else
      this.setState({
        userSelectItems: Boolean(response2.data) ? response2.data : []
      });

    let url3 = "/api/companies/authorized";

    const response3 = await handleRequest("GET", url3);

    if (response3.type === "ERROR") {
      error(response3);
    } else
      this.setState({
        companySelectItemms: Boolean(response3.data) ? response3.data : []
      });

    let url4 = "/api/projects/authorized";

    const response4 = await handleRequest("GET", url4);

    if (response4.type === "ERROR") {
      error(response4);
    } else
      this.setState({
        projectSelectItems: Boolean(response4.data) ? response4.data : []
      });
  };

  save = async () => {
    let folderList = [],
      companyList = [],
      projectList = [];
    let selectedUserIds = this.state.selectedUserIds;
    let selectedCompanyIds = this.state.selectedCompanyIds;
    let selectedProjectIds = this.state.selectedProjectIds;

    if (Boolean(selectedUserIds) && selectedUserIds.length > 0)
      selectedUserIds.forEach(userId => {
        selectedCompanyIds.forEach(id => {
          let obj = { userId: userId, companyId: id };
          companyList.push(obj);
        });

        selectedProjectIds.forEach(id => {
          let obj = { userId: userId, projectId: id };
          projectList.push(obj);
        });
      });

    var response = await handleRequest("POST", "/api/users/authorizeCompany", { projectList: projectList, companyList: companyList });

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.resetInputs();
      this.restartTable();
      showSuccess();
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout2 = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const columns = [
      {
        title: "Actions",
        key: "Actions",
        render: record => {
          return this.actionTemplate(record);
        }
      },

      {
        title: "S/N",
        key: "index",
        render: (value, row, index) => {
          return index + 1;
        }
      },
      {
        title: "User",
        key: "user",
        render: record => {
          return record.name + " " + record.surname + " " + record.username;
        }
      },
      {
        title: "Companies",
        key: "company",
        render: record => {
          return record.companyNames;
        }
      },
      {
        title: "Projects",
        key: "projects",
        render: record => {
          return record.projectNames;
        }
      }
    ];

    return (
      <div hidden={this.state.hideInputs} className="card card-custom" style={{ padding: "2rem" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
          {
            <Form.Item
              {...layout2}
              label={<FormattedMessage id="todo" defaultMessage="Users" />}
              name="selectedUserIds"
              rules={[{ required: true, message: <FormattedMessage id="todo" defaultMessage="Select at least one user." /> }]}
            >
              <Select
                style={{ width: "100%" }}
                mode="multiple"
                placeholder="Select users"
                id="selectedUserIds"
                value={this.state.selectedUserIds}
                onChange={value => {
                  this.setState({ selectedUserIds: value });
                }}
              >
                <Option key={null} value={null}>
                  Select
                </Option>
                {this.state.userSelectItems.map(i => (
                  <Option value={i.id}>{i.username}</Option>
                ))}
              </Select>
            </Form.Item>
          }
          {
            <Form.Item
              {...layout2}
              label={<FormattedMessage id="todo" defaultMessage="Companies" />}
              name="selectedCompanyIds"
              rules={[{ required: true, message: <FormattedMessage id="todo" defaultMessage="Select at least one company." /> }]}
            >
              <Select
                style={{ width: "100%" }}
                mode="multiple"
                placeholder="Select companies"
                id="selectedCompanyIds"
                value={this.state.selectedCompanyIds}
                onChange={value => {
                  this.setState({ selectedCompanyIds: value });
                }}
              >
                <Option key={null} value={null}>
                  Select
                </Option>
                {this.state.companySelectItemms.map(i => (
                  <Option value={i.id}>{i.companyName}</Option>
                ))}
              </Select>
            </Form.Item>
          }
          {
            <Form.Item
              {...layout2}
              label={<FormattedMessage id="todo" defaultMessage="Projects" />}
              name="selectedProjectIds"
              rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Select at least one project." /> }]}
            >
              <Select
                style={{ width: "100%" }}
                mode="multiple"
                placeholder="Select projects"
                id="selectedProjectIds"
                value={this.state.selectedProjectIds}
                onChange={value => {
                  this.setState({ selectedProjectIds: value });
                }}
              >
                <Option key={null} value={null}>
                  Select
                </Option>
                {this.state.projectSelectItems.map(i => (
                  <Option value={i.id}>{i.projectFullName}</Option>
                ))}
              </Select>
            </Form.Item>
          }

          <div hidden={this.state.hideSave}>
            {
              <Row gutter={8}>
                <Col span={8}></Col>

                <Col span={8}>
                  {
                    <Button id="OrganizationalLevelSaveButton" type="submit" style={{ width: "100%" }} variant="success">
                      <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                    </Button>
                  }
                </Col>
              </Row>
            }
          </div>

          <div hidden={this.state.hideUpdate}>
            {
              <Row gutter={8}>
                <Col span={8}></Col>

                <Col span={4}>
                  <Button id="OrganizationalLevelCancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                    <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                  </Button>
                </Col>
                <Col span={4}>
                  <Button id="OrganizationalLevelUpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                    <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                  </Button>
                </Col>
              </Row>
            }
          </div>
        </Form>

        <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
          <Table bordered scroll={{ x: 1300, scrollToFirstRowOnChange: true }} columns={columns} dataSource={this.state.tableList} />
        </div>
        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.delete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(UserCompany);
